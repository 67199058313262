/* You can add global styles to this file, and also import other style files */
html,
body {
  background-color: var(--black-color);
  font-family: var(--font-family-sans-serif);
  line-height: 1.625;
  margin: 0;
  padding: 0;
}

/*
 RESET CSS
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

/* .logo {
  vertical-align: middle;
} */

input,
button,
textarea,
select {
  font: inherit;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
#root,
#__next {
  isolation: isolate;
}

.disabled {
  background-color: var(--secondary-color);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  width: 5rem;
  height: 5rem;
  margin: 2rem auto;
  border: 0.75rem solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

#imageElement {
  max-width: 100%;
  width: 50rem;
  margin: auto;
  padding: 1rem;
  display: block;
}

.response-text {
  .image-not-available {
    color: red;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 500;

    img#imageElement {
      width: 100px;
    }
  }
}
