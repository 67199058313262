:root {
  --primary-color: #4abdcf;
  --orange-color: #f39600;
  --secondary-color: #424242;
  --success-color: #28a745;
  --info-color: #17a2b8;
  --warning-color: #ffb612;
  --danger-color: #e05200;
  --light-color: #e5f1ff;
  --dark-color: #3c3733;
  --white-color: #ffffff;
  --font-color: white;
  --font-size: 0.8rem;
  --message-light-color: transparent;
  --message-light-font-color: white;
  --message-dark-color: transparent;
  --neutral-color: transparent;
  --message-dark-font-color: white;
  --black-color: #171717;
  --gray-color: #ccc;
  --background-color: #212121;
  --background-bright-color: #282828;
  --gray-dark-color: #343a40;
  --font-family-sans-serif: ui-sans-serif, system-ui, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.disabled {
  background-color: var(--secondary-color);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}